import React, { useState } from 'react';
import ortho1bg from '../../assets/ortho/ortho1bg.png';
import bg1 from '../../assets/ortho/orthoBG1.png';
import logo from '../../assets/ortho/logo.png';
import logoMobile from '../../assets/ortho/logoMobile.svg';
import scrollTo from 'gatsby-plugin-smoothscroll';
import * as S from './Ortho1.styles';

export default () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <S.Wrapper>
      <S.Overlay background={bg1} />
      <S.ImgContainer background={ortho1bg} />
      <S.InnerWrapper>
        <S.NavBar>
          <S.NavInnerWrapper>
            <S.Logo src={logo} />
            <S.MobileLogo src={logoMobile} />
            <S.LinksWrapper>
              <S.StyledLink onClick={() => scrollTo('#for-whom')}>Dla kogo?</S.StyledLink>
              <S.StyledLink onClick={() => scrollTo('#how-it-works')}>Jak to działa?</S.StyledLink>
              <S.StyledLink onClick={() => scrollTo('#contact')}>Kontakt</S.StyledLink>
            </S.LinksWrapper>
            <S.BurgerWrapper onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <S.Close /> : <S.Burger />}
            </S.BurgerWrapper>
          </S.NavInnerWrapper>
        </S.NavBar>
        {isMenuOpen ? (
          <S.LinksMobileWrapperWrapper isActive={isMenuOpen}>
            <S.StyledLink onClick={() => scrollTo('#for-whom')}>Dla kogo?</S.StyledLink>
            <S.StyledLink onClick={() => scrollTo('#how-it-works')}>
              Jak to działa?
            </S.StyledLink>
            <S.StyledLink onClick={() => scrollTo('#contact')}>Kontakt</S.StyledLink>
          </S.LinksMobileWrapperWrapper>
        ) : null}
        <S.Body>
          <S.Column>
            <S.Header>
              Operacje ortopedyczne ze wsparciem <S.Bold>Augmented Reality</S.Bold>
            </S.Header>
            {/* <S.MoreButton>Więcej</S.MoreButton> */}
          </S.Column>
        </S.Body>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};
