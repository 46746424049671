import React from 'react';
import * as S from './OrthoWrapper.styles';

export default ({
  img,
  children,
  isReversed = false,
  bgColor = '#fff',
  bodyStyles = '',
  background,
  wrapperStyles,
  id,
  isOverlay = false,
}: {
  bgColor?: string;
  isReversed?: boolean;
  img: string;
  children: React.ReactChild;
  bodyStyles?: string;
  wrapperStyles?: string;
  background: string;
  id?: string;
  isOverlay?: boolean;
}) => (
  <S.Wrapper id={id} isReversed={isReversed} wrapperStyles={wrapperStyles} bgColor={bgColor} background={isOverlay ? '' : background}>
    {isOverlay && <S.OverlayBackround background={background} />}
    <S.BodyWrapper isReversed={isReversed}>
        <S.Body bodyStyles={bodyStyles} >
          {children}
        </S.Body>
    </S.BodyWrapper>
      <S.ImageContainer>
        <S.SideImage src={img} />
      </S.ImageContainer>
  </S.Wrapper>
);
