import React from 'react';
import OrthoWrapper from '../OrthoWrapper/OrthoWrapper';
import LP2 from '../../assets/ortho/LP2.png';
import orthoBG2 from '../../assets/ortho/orthoBG2.png';
import * as S from './Ortho2.styles';

export default () => (
  <OrthoWrapper
  id="for-whom"
  img={LP2}
  background={orthoBG2}
  >
    <S.Wrapper>
      <S.Header>Co to jest AR?</S.Header>
      <S.Text>
        Rzeczywistość rozszerzona (ang. augmented reality) to szereg technologii łączących świat
        rzeczywisty z obrazem generowanym komputerowo. Obraz z kamery lub specjalnych okularów
        łączony jest w czasie rzeczywistym z trójwymiarową grafiką, dzięki czemu użytkownicy
        technologii AR mogą obserwować rzeczywistość wzbogaconą o dodatkowe elementy. Postępujący
        rozwój rzeczywistości rozszerzonej pozwala na stosowanie jej w coraz nowych dziedzinach - od
        marketingu i gier komputerowych po muzealnictwo i edukację.
      </S.Text>
    </S.Wrapper>
  </OrthoWrapper>
);
