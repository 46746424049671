import React from 'react';
import OrthoWrapper from '../OrthoWrapper/OrthoWrapper';
import LP4 from '../../assets/ortho/LP4.png';
import orthoBG2 from '../../assets/ortho/orthoBG2.png'
import * as S from '../Ortho2/Ortho2.styles';

export default () => (
  <OrthoWrapper
    img={LP4}
    background={orthoBG2}
    isReversed
    id="how-it-works"
    isOverlay
  >
    <S.Wrapper margin="0 0 0 auto">
      <S.Header>Rzeczywistość rozszerzona a&nbsp;operacje ortopedyczne</S.Header>
      <S.Text>
        Wykorzystanie obrazowania 3D i okularów do rozszerzonej rzeczywistości to przełomowy pomysł,
        który umożliwia lekarzowi korzystanie z bardzo dokładnych danych diagnostycznych i
        uniknięcie potencjalnych błędów w sztuce. Dzięki aplikacji Orthoeye lekarz może szybko
        zweryfikować poprawne ułożenie narzędzia czy detale anatomiczne pacjenta w ciągu zaledwie
        kilku sekund. Ponadto, operowane miejsce wizualizowane jest w formie hologramu, na którym
        można umieścić pomocnicze linie i punkty, takie jak osie śrub. Oznaczenia te widoczne są
        podczas zabiegu, dzięki czemu działania chirurga są precyzyjniejsze. Dodatkowo, aplikacja
        automatycznie wylicza kąty obrotu i odległość przemieszczenia narzędzi.
      </S.Text>
    </S.Wrapper>
  </OrthoWrapper>
);
