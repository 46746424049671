import React from 'react';
import * as S from './OrtheyeColumnWrapper.styles';

interface ColumnProps {
  title: string;
  body: string;
}

export default ({
  columns,
  background,
  id
}: {
  columns: ColumnProps[];
  color?: string;
  background: string;
  headerStyles?: string;
  id?: string
}) => (
  <S.Wrapper id={id} background={background}>
    <S.InnerWrapper>
      {columns.map(({ title, body }: ColumnProps, index) => (
        <S.Column key={index}>
          <S.Header>
            {title}
          </S.Header>
          <S.Text>{body}</S.Text>
        </S.Column>
      ))}
    </S.InnerWrapper>
  </S.Wrapper>
);
