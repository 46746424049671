import React from 'react';
import orthoBG2 from '../../assets/ortho/orthoBG2.png'
import OrtheyeColumnWrapper from '../OrtheyeColumnWrapper/OrtheyeColumnWrapper';

const data = [
  {
    title: 'Augumented Reality w\xa0medycynie',
    body: `
    Czy AR może okazać się użyteczna również w medycynie? Badania kliniczne prowadzone w ostatnich latach dowiodły, że jej wykorzystanie w praktyce chirurgicznej, poradnictwie śródoperacyjnym i diagnostyce może przynosić obiecujące efekty. Opisano już zakończone powodzeniem próby stosowania rzeczywistości rozszerzonej w zabiegach takich jak resekcja guza, naprawa złamania, artroskopia czy wyrównanie elementu w całkowitej alloplastyce stawu.`,
  },
  {
    title: 'Orthoeye - wsparcie operacji ortopedycznych',
    body: `
    Orthoeye to aplikacja umożliwiająca wykorzystanie rozszerzonej rzeczywistości podczas ortopedycznych zabiegów chirurgicznych, takich jak urazowe zespolenie złamań kości. Aplikacja uruchamiana jest przy pomocy okularów typu Hololens noszonych przez wykonującego zabieg chirurga. Generowany w czasie rzeczywistym obraz cyfrowy nakładany jest na pole widzenia lekarza, co pozwala mu uzyskać dostęp do niezbędnych danych i dokładnego obrazowania operowanego pacjenta. Korzystając z okularów do rozszerzonej rzeczywistości, chirurg pozostaje całkowicie sterylny. W razie konieczności, może za ich pomocą szybko skontaktować się z innymi lekarzami znajdującymi się poza salą operacyjną.`,
  },
];

export default () => (
  <OrtheyeColumnWrapper
    id="how-it-works"
    background={orthoBG2}
    columns={data}
  />
);
