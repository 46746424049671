import * as React from "react"
import Ortho1 from '../components/Ortho1/Ortho1';
import Ortho2 from '../components/Ortho2/Ortho2';
import Ortho3 from '../components/Ortho3/Ortho3';
import Ortho4 from '../components/Ortho4/Ortho4';
import Ortho5 from '../components/Ortho5/Ortho5';
import Ortho6 from '../components/Ortho6/Ortho6';
import "../components/layout.css";

const IndexPage = () => (
  <>
    <Ortho1 />
    <Ortho2 />
    <Ortho3 />
    <Ortho4 />
    <Ortho5 />
    <Ortho6 />
  </>
)

export default IndexPage
