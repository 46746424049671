import styled from 'styled-components';
import orthoBG2 from '../../assets/ortho/orthoBG2.png';
import LP5 from '../../assets/ortho/LP5.png';

export const ExternalWrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  background-image: url(${orthoBG2});
  background-size: cover;

  @media(max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const OverlayBackround = styled.div`
  background-image: url(${orthoBG2});
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  z-index: 0;

  @media(max-width: 680px){
    opacity: 0.5;
  }
`

export const BodyWrapper = styled.div`
  flex-grow: 1;
  box-sizing: border-box;
  justify-content: flex-end;
  display: flex;

  @media (max-width: 1164px) {
    padding: 0 16px 32px;
    min-width: initial;
  }

  @media(max-width: 1100px) {
    justify-content: center;
  }
`;

export const Body = styled.div`
  max-width: 862px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;

  @media (max-width: 1100px) {
    min-height: auto;
    margin: 0 auto;
  }
`;

export const ImageContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  object-position: right;
  object-fit: cover;
  min-height: 100vh;
  background-image: url(${LP5});

  @media (max-width: 1100px) {
    min-height: auto;
    background-image: none;
  }
`;

export const Wrapper = styled.div<{ padding?: string, stickToLeft?: boolean }>`
  max-width: 677px;
  width: 100%;
  height: 485px;
  margin: auto 0;
  padding: ${(props) => props.padding};

  @media(max-width: 1100px) {
    margin: 0 auto;
    padding: 0;
    height: auto;
    padding: 37px 0;
  }

  @media(min-width: 1400px) {
    margin-left: ${(props) => props.stickToLeft ? '20%;' : ''};
  }
`;

export const Header = styled.h2`
  font-family: MuseoSansRoundedBold;
  font-size: 45px;
  font-weight: 900;
  line-height: 1.22;
  color: #1b62ad;
  margin: 0 0 62px;
  min-height: 108px;

  @media(max-width: 405px) {
    font-size: 34px;
  }
`;

export const WhiteHeader = styled(Header)`
  color: #fff;

  @media (max-width: 1100px) {
    color: #1b62ad;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-family: MuseoSansRounded;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.75;
  color: #404041;

  @media (max-width: 1100px) {
    background-color: #fff;
    box-shadow: 0px 0px 10px #fff;
  }
`;

export const WhiteText = styled(Text)`
  background-color: transparent;
  color: #fff;

  @media (max-width: 1100px) {
    color: #404041;
    background-color: #fff;
    box-shadow: 0px 0px 10px #fff;
  }
`;
