import styled from "styled-components"

export const Wrapper = styled.section<any>`
  background-color: ${props => props.bgColor};
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${props => props.isReversed ? 'row-reverse' : 'row'};
  background-image: url(${props => props.background});
  background-size: cover;

  @media(max-width: 1546px){
    ${props => props.wrapperStyles}
  }
`

export const OverlayBackround = styled.div<{ background: string }>`
  background-image: url(${props => props.background});
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  z-index: 0;

  @media(max-width: 680px){
    opacity: 0.5;
  }
`

export const BodyWrapper = styled.div<any>`
  min-width: 1000px;
  flex-grow: 1;
  justify-content: ${props => props.isReversed ? 'flex-start' : 'flex-end'};
  ${props => props.isReversed ? 'padding-left: 54px;' : 'padding-right: 54px;'};
  display: flex;

  @media(max-width: 1164px) {
    padding: 0 16px 32px;
    min-width: initial;
  }
`;

export const Body = styled.div<any>`
  max-width: 862px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  ${props => props.bodyStyles}
`

export const ImageContainer = styled.div`
  min-height: 900px;
  flex-grow: 1;
  overflow: hidden;
  height: 100vh;
  object-position: right;
  object-fit: cover;
`;

export const SideImage = styled.img`
  min-height: 900px;
  flex-grow: 1;
  height: 100vh;
  object-fit: cover;
  object-position: center;

  @media(max-width: 1165px) {
    display: none;
  }
`
