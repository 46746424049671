import React from 'react';
import * as S from './Ortho5.styles';

export default () => (
  <S.ExternalWrapper>
    <S.BodyWrapper>
      <S.Body>
        <S.Wrapper>
          <S.Header>Krótsze naświetlanie - mniejsze ryzyko powikłań</S.Header>
          <S.Text>
            Operacja ortopedyczna o wysokim stopniu skomplikowania oznacza w dzisiejszych czasach
            konieczność wykonania nawet kilkudziesięciu zdjęć rentgenowskich. Zdjęcia te wykonywane
            są nie tylko na etapie przygotowania do zabiegu, ale też na sali operacyjnej. Tak długie
            naświetlanie nie jest obojętne dla stanu zdrowia pacjenta, którego organizm i tak jest
            obciążony długotrwałym zabiegiem i urazami, które do niego doprowadziły. Wykorzystanie
            Hololens pozwala na uzyskanie precyzyjnego obrazu na podstawie zaledwie jednego zdjęcia
            rentgenowskiego, które wykonywane jest na początku zabiegu.
          </S.Text>
        </S.Wrapper>
      </S.Body>
    </S.BodyWrapper>

    <S.ImageContainer>
      <S.Body>
        <S.Wrapper padding="0 auto 0 0;" stickToLeft={true}>
          <S.WhiteHeader>Orthoeye to:</S.WhiteHeader>
          <S.WhiteText>
            - poprawa bezpieczeństwa pacjenta<br /> 
            - skrócenie czasu zabiegu<br /> 
            - zmniejszenie ryzyka powikłań<br /> 
            - krótszy czas naświetlania promieniami RTG
          </S.WhiteText>
        </S.Wrapper>
      </S.Body>
    </S.ImageContainer>
  </S.ExternalWrapper>
);
