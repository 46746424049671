import styled from 'styled-components';

export const Wrapper = styled.div<any>`
  margin: auto 0;
  max-width: 677px;
  width: 100%;
  background-image: url(${(props) => props.src});

  @media (max-width: 948px) {
    background-position: right;
  }
`;

export const FlexWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Column = styled.div`
  max-width: 689px;
  width: 100%;
  height: 100vh;
`;

export const NarrowColumn = styled.div`
  max-width: 463px;
  width: 100%;
  height: 100vh;
`;

export const Header = styled.h2`
  font-family: MuseoSansRoundedBold;
  font-size: 45px;
  font-weight: 900;
  line-height: 1.22;
  color: #1b62ad;
  margin: 0 0 62px;

  @media(max-width: 405px) {
    font-size: 34px;
    margin-top: 20px;
  }
`;

export const NarrowHeader = styled(Header)`
  color: #fff;
`;

export const Text = styled.p`
  margin: 0;
  font-family: MuseoSansRounded;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.75;
  color: #404041;
  background-color: #fff;
`;

export const NarrowText = styled(Text)`
  color: #fff;
`;
