import styled from 'styled-components';
import { MdMenu, MdClose } from 'react-icons/md';

export const Wrapper = styled.section`
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-size: cover;

  @media (max-width: 665px) {
    background-position: right;
  }
`;

export const Overlay = styled.section<{ background: string }>`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  background-image: url(${(props) => props.background});
  background-size: cover;
  
  @media (max-width: 1345px) {
    opacity: 0.4;
  }

  @media (max-width: 665px) {
    background-position: right;
  }
`;

export const InnerWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  @media (min-width: 665px) {
    padding-top: 50px;
  }
`;

export const NavBar = styled.nav`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 212px;
  background-color: #fff;
  z-index: 1;
  position: relative;
  padding: 0 16px;
  max-height: 20vh;
  min-height: 100px;

  @media (min-width: 780) {
    margin: 0 auto 30px;
  }
`;

export const NavInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1456px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
`;

export const Logo = styled.img`
  margin: 0;

  @media (max-width: 550px) {
    display: none;
  }
`;

export const MobileLogo = styled.img`
  margin: 0;
  height: 80px;

  @media (min-width: 551px) {
    display: none;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  margin-left: 500px;

  @media (max-width: 1440px) {
    width: auto;
    margin: 0;
  }

  @media(max-width: 781px) {
    display: none;
  }
`;

export const StyledLink = styled.div`
  font-family: MuseoSansRoundedBold;
  font-size: 19px;
  font-weight: 900;
  color: #404041;
  margin: 17px 15px;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 550px) {
    font-size: 15px;
  }
`;

export const Body = styled.div`
  max-width: 1420px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 16px;
  box-sizing: border-box;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  margin-top: 18vh;
`;

export const ImgContainer = styled.div<{ background: string }>`
  position: absolute;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background-image: url(${(props) => props.background});
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left;
  background-repeat: no-repeat;
  z-index: 0;

  @media (max-width: 665px) {
    display: none;
  }

  @media (max-width: 1345px) {
    opacity: 0.4;
  }
`;

export const Column = styled.div`
  max-width: 609px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.h1`
  font-family: MuseoSansRounded;
  font-size: 57px;
  font-weight: 300;
  line-height: 1.09;
  color: #1b62ad;
  margin: 0 0 22%;

  @media(max-width: 400px) {
    font-size: 45px;
  }
`;

export const Bold = styled.span`
  font-family: MuseoSansRoundedBold;
  color: #000;
`;

export const MoreButton = styled.button`
  cursor: pointer;
  width: 246px;
  height: 64px;
  box-sizing: border-box;
  background-color: #1b62ad;
  border-radius: 44px;
  margin: 0 auto 0 0;
  border: none;
  padding: 0;
  text-transform: uppercase;
  text-decoration: none;
  font-family: MuseoSansRoundedBold;
  font-size: 22px;
  font-weight: 900;
  line-height: 1.18;
  color: #fff;
`;

export const LinksMobileWrapperWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  z-index: 3;
  left: 0;
  height: calc(100vh - 200px);
  height: auto;
  transition: right 1.2s ease-out 0s;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid;

  @media (min-width: 781px) {
    display: none;
  }
`;

export const Burger = styled(MdMenu)`
  color: #1b62ad;
  height: 41px;
  width: 41px;
`;

export const Close = styled(MdClose)`
  color: #1b62ad;
  height: 41px;
  width: 41px;
`;

export const BurgerWrapper = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 0;
  height: 41px;
  width: 41px;
  outline: none;

  @media (min-width: 781px) {
    display: none;
  }
`;
